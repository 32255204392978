import React, { useState, useEffect, useRef } from "react";
import qr2 from "../../../data/assets/docqr.png";
import playstorebtn from "../../../data/assets/playstore_btn.svg";
import appstorebtn from "../../../data/assets/appstore_btn.svg";
import { AppSection } from "./styles";
import { message } from 'antd';
import { API_ROOT } from "gatsby-env-variables";

const App1 = () =>
{
    /*===================Enter Keypress event=======================*/
    function useKey(key, cb) {
        const callbackRef = useRef(cb);
    
        useEffect(() => {
          callbackRef.current = cb;
        })
    
        useEffect(() => {
          function handle(event) {
            if (event.code === key) {
              callbackRef.current(event);
            }
          }
          document.addEventListener("keypress", handle);
        }, [key]);
      }
       function handleEnter ()
    {
        sendDoc();
    }
    // useKey("Enter", handleEnter)
    /*==================Api calling for doctor form====================*/
    const [ mobile_no2, setMobile_no2 ] = useState( "" );

    async function sendDoc ()
    {
        let item2 = { mobile_no2 }

        let docResult = await fetch( API_ROOT + "Spotcare/getDoctorLink", {
            method: "POST",
            body: JSON.stringify( item2 ),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        } )

        docResult = await docResult.json();
        if ( docResult.status === true )
        {
            success();
            setMobile_no2( "" );
        } else
        {
            error();
        }
    }
    const success = () =>
    {
        message.success( 'Link Sent' );
    };

    const error = () =>
    {
        message.error( 'Please try again' );
    };
    const warning = () =>
    {
        message.warning( 'Enter your mobile number' );
    };

    return (
        <AppSection >
                           <div id="qr_conatiner">
                  
                  <div id="qr_conatiner_block1">
                    <div id="ql_container_matter_container">
                      <div id="ql_container_matter">
                        <h1>SpotCare® For Doctors & Health Service Providers</h1>
                        <p>Adding value to the lives of patients, doctors, health workers, pharmacies, diagnostics and healthcare institutions with the help of new-age technology</p>
                      </div>
                      <div id="qr_container_input_block" style={{ position: `relative` }}>
                        <h1>Get the link to Download the App</h1>
                        <div id="qr_container_input_block_container">
                          <div id="qr_container_input_block_container_container">
                            <p>+91</p>
                            <input type="text" placeholder="Enter Mobile Number" value={mobile_no2} onChange={(e) => setMobile_no2(e.target.value || "")} maxLength={10} minLength={10} onKeyPress={event => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                          </div>
                          {(!mobile_no2 || mobile_no2.length < 10 ) ? (
                                                            <button onClick={ warning } style={{background: `gray`}}>Get App Link</button> ) :(
                                                                <button onClick={sendDoc}>Get App Link</button>
                                                        )}
                        </div>
                      </div>
                    </div>
                  </div>
                <div id="qr_conatiner_block2">
                    <div id="qrs">
                        <div id="qu_image_container">
                            <img src={ qr2 } alt="qr" />
                        </div>
                        <div id="qr_image_button">
                        <a href="https://play.google.com/store/apps/details?id=com.naturalminds" target="_blank" rel="noopener noreferrer" ><button><img src={playstorebtn} alt="btn" />Google Play</button></a>
                        <a href="https://apps.apple.com/in/app/spotcare-care-provider/id1528551730" target="_blank" rel="noopener noreferrer" ><button><img src={appstorebtn} alt="btn" />App Store</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </AppSection>
    );
};

export default App1;